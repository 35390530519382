import React from "react";
import styled from "styled-components";

const Locals = () => {
  return <StyledLocals>Les streets Business seront affichés ici</StyledLocals>;
};

export default Locals;

const StyledLocals = styled.div`
  background: pink;
  @media screen and (max-width: 443px) {
    margin-top: 50px;
    padding-top: 30px;
  }
`;
