import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BiSolidCalendarAlt } from "react-icons/bi";
import { COLORS } from "../../COLORS";
import FormAnnonce from "./Forms/Annonce/FormAnnonce";
import FormEvenement from "./Forms/Evenement/FormEvenement";
import { HiAnnotation } from "react-icons/hi";
import { BiSolidBusiness } from "react-icons/bi";
import FormBusiness from "./Forms/Business/FormBusiness";
import LilAdsGlobal from "../Ads/LilAdsGlobal";

const Post = () => {
  const [chooseForm, setChooseForm] = useState("Un événement ?");

  const changeForm = () => {
    switch (chooseForm) {
      case "Un événement ?":
        return <FormAnnonce />;
      case "Une annonce ?":
        return <FormEvenement />;
      case "Business":
        return <FormBusiness />;
      default:
        return <FormAnnonce />;
    }
  };
  return (
    <StyledPost>
      <h1>Publication</h1>
      <LilAdsGlobal />
      <div
        className="change-form-box"
        onClick={() =>
          setChooseForm(
            chooseForm === "Une annonce ?" ? "Un événement ?" : "Une annonce ?"
          )
        }
      >
        <strong>
          {chooseForm === "Un événement ?" || chooseForm === "Business"
            ? "Un événement ?"
            : "Une annonce ?"}
        </strong>
        {chooseForm === "Une annonce ?" ? (
          <HiAnnotation className="icon-publish" />
        ) : (
          <BiSolidCalendarAlt className="icon-publish" />
        )}
      </div>
      <div
        className="change-form-box"
        onClick={() =>
          setChooseForm((prev) =>
            prev === "Business" ? "Un événement ?" : "Business"
          )
        }
      >
        <BiSolidBusiness className="icon-business" />
        <strong>
          {chooseForm === "Business" ? "Retour" : "Votre business"}
        </strong>
      </div>
      {changeForm()}
    </StyledPost>
  );
};

export default Post;
const StyledPost = styled.section`
  padding: 10px;
  h1 {
    text-align: center;
    font-size: 3.7em;
    margin-bottom: 10px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: ${COLORS.purple};
  }
  .change-form-box {
    width: 20%;
    padding: 10px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${COLORS.purple};
    border-radius: 20px;
    box-shadow: 0px 2px 5px ${COLORS.green};
    cursor: pointer;
    strong {
      font-size: 1.6em;
      color: ${COLORS.white};
      text-shadow: 0px 2px 5px ${COLORS.green};
    }
    .icon-publish {
      font-size: 3.7em;
      color: ${COLORS.white};
    }

    .icon-business {
      font-size: 2.1em;
      color: ${COLORS.white};
    }
  }

  //width =< 429px
  @media screen and (max-width: 429px) {
    /* padding-top: 100px; */
    padding: 100px 10px 0px 10px;
    width: 100%;
    h1 {
      font-size: 2em;
    }
    .change-form-box {
      width: 50%;
      strong {
        font-size: 1.3em;
      }
      .icon-publish {
        font-size: 2.8em;
      }
    }
  }
`;
