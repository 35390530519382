import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import axios from "axios";
import Evenement from "./Evenement/Evenement";
import { useNavigate } from "react-router-dom";
type TypeState = {
  createdAt: string;
  description: string;
  image: [string];
  ville: string;
  pseudo: string;
  status: number;
};
const Evenements = () => {
  const [cities, setCities] = useState<[string]>();
  const [selected, setSelected] = useState("");
  const [events, setEvents] = useState<TypeState[]>([]);
  const nav = useNavigate();

  const selectedVille = (cat: string) => {
    if (cat === selected) {
      setSelected("");
    } else {
      setSelected(cat);
      getEventFiltre(cat);
    }
  };
  const getAllCityEvents = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}evenement/all`,
        withCredentials: true,
      });
      if (res.data) {
        setCities(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEventFiltre = async (param: string) => {
    try {
      const res = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}evenement/filter/${param}`,
        withCredentials: true,
      });
      console.log(res);
      if (res.data) {
        setEvents(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCityEvents();
  }, []);
  return (
    <StyledEvenements>
      <h1>Les évènements publiés</h1>
      <div className="box-list-city">
        {cities && cities.length > 0 ? (
          cities.map((cit, index) => (
            <StyledLabelListCity
              $css={selected === cit}
              key={index}
              onClick={() => selectedVille(cit)}
            >
              {cit}
            </StyledLabelListCity>
          ))
        ) : (
          <strong>Aucun</strong>
        )}
      </div>
      {selected && (
        <div className="box-to-compo-one animate__animated animate__bounceIn">
          <strong>
            {events.length} évènement{events.length > 1 ? "s" : ""} sur{" "}
            {selected}
          </strong>
          {events &&
            events.length > 0 &&
            events.map((ev, index) => (
              <Evenement
                key={index}
                createdAt={ev.createdAt}
                description={ev.description}
                image={ev.image}
                ville={ev.ville}
                pseudo={ev.pseudo}
              />
            ))}
        </div>
      )}
      <div className="go-publish-event">
        <button onClick={() => nav("/post")}>Publier un évènement </button>
      </div>
    </StyledEvenements>
  );
};

export default Evenements;
const StyledEvenements = styled.div`
  padding: 10px;
  h1 {
    letter-spacing: 0.3em;
    text-align: center;
  }
  .box-list-city {
    background: pink;
    width: 70%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 10px;
  }
  .box-to-compo-one {
    width: 95%;
    overflow-x: auto;
    margin: 0px auto;
    /* background: green; */
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    strong {
      position: absolute;
      z-index: 1000;
      top: 10px;
      left: 10px; /* Évite de pousser les éléments horizontalement */
      margin-right: 10px; /* Ajoute un espace entre strong et le contenu */
      padding: 5px;
      border-radius: 5px;
      background: ${COLORS.gold};
      color: ${COLORS.green};
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      display: inline-block;
      flex-shrink: 0;
    }
  }
  .box-to-compo-one::-webkit-scrollbar {
    height: 8px; /* Taille de la scrollbar horizontale */
  }
  .box-to-compo-one::-webkit-scrollbar-thumb {
    background: ${COLORS.green}; /* Couleur de la barre de défilement */
    border-radius: 10px;
  }
  .go-publish-event {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    button {
      padding: 10px;
      font-size: 1.2em;
      outline: none;
      letter-spacing: 0.2em;
      border: none;
      border-radius: 10px;
      background: ${COLORS.green};
      color: ${COLORS.white};
      cursor: pointer;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    }
  }
  @media screen and (max-width: 442px) {
    margin-top: 50px;
    padding-top: 30px;
    .box-list-city {
      width: 90%;
    }
    .box-to-compo-one {
      width: 100%;
    }
  }
`;

const StyledLabelListCity = styled.label<{ $css: boolean }>`
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: ${({ $css }) => ($css ? COLORS.green : COLORS.white)};
  color: ${({ $css }) => ($css ? COLORS.white : COLORS.black)};
  transition: 0.3s ease, color 0.3s ease;
  font-size: 1.6em;
  @media screen and (max-width: 442px) {
    margin: 5px;
    font-size: 1.2em;
  }
`;
