import React from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const nav = useNavigate();
  const location = useLocation();
  return (
    <StyledHeader>
      <img src="/items/fulllogo.svg" alt="logo-full" onClick={() => nav("/")} />
      <nav>
        <ol>
          <li
            onClick={() => nav("/")}
            className={location.pathname === "/" ? "active" : ""}
          >
            Accueil
          </li>
          <li
            onClick={() => nav("/annonces")}
            className={
              location.pathname === "/annonces" ||
              location.pathname === "/simples"
                ? "active"
                : ""
            }
          >
            Annonces
          </li>
          <li
            onClick={() => nav("/evenements")}
            className={location.pathname === "/evenements" ? "active" : ""}
          >
            Evenements
          </li>
          <li
            onClick={() => nav("/locals")}
            className={
              location.pathname === "/locals" ? "local active" : "local"
            }
          >
            Local
          </li>
          <li
            onClick={() => nav("/points")}
            className={location.pathname === "/points" ? "active" : ""}
          >
            Points
          </li>
          <li
            onClick={() => nav("/post")}
            className={location.pathname === "/post" ? "active" : ""}
          >
            Publier
          </li>
        </ol>
      </nav>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  background: ${COLORS.white};
  border-bottom-width: 2px;
  /* border-bottom: dashed ${COLORS.black}; */
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;
  img {
    display: block;
    width: 10%;
    cursor: pointer;
  }
  nav {
    /* background: red; */
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    ol {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .local {
        color: ${COLORS.purple};
      }
      li {
        font-size: 1.2em;
        //font-weight: 700;
        cursor: pointer;
      }
      .active {
        transition: 0.1s;
        padding-bottom: 3px;
        border-bottom: solid 3px ${COLORS.green};
      }
    }
  }
  //width =< 429px
  @media screen and (max-width: 429px) {
    justify-content: center;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 20;
    img {
      width: 35%;
    }
    nav {
      display: none;
    }
  }
`;
