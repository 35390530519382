import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../COLORS";
type TypePropFilterAnnonce = {
  setGetCat: React.Dispatch<React.SetStateAction<string>>;
};
const FilterAnnonce = ({ setGetCat }: TypePropFilterAnnonce) => {
  const [categorie, setCategorie] = useState([
    "Auto-Moto",
    "Immobilier",
    "Maison",
    "Loisirs",
    "Multimédia",
    "Services",
    "Divers",
  ]);
  const [selected, setSelected] = useState("");

  const selectedRadio = (cat: string) => {
    console.log(cat);
    console.log(selected);

    if (cat === selected) {
      console.log("bou");
      setSelected("");
      setGetCat("");
    } else {
      console.log("je joue icc");
      setGetCat(cat);
      setSelected(cat);
    }
  };
  return (
    <StyledFilterAnnonce>
      <div className="box">
        {categorie.map((cat, index) => (
          <StyledLabel
            key={index}
            $css={selected === cat}
            onClick={() => selectedRadio(cat)}
          >
            {/* <input
              type="radio"
              checked={selected === cat}
              onChange={() => selectedRadio(cat)}
            /> */}
            {cat}
          </StyledLabel>
        ))}
      </div>
    </StyledFilterAnnonce>
  );
};

export default FilterAnnonce;
const StyledFilterAnnonce = styled.div`
  /* background: red; */
  width: 100%;
  display: flex;
  justify-content: center;
  .box {
    width: 50%;
    margin: 5px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 429px) {
    margin-top: 0px;
    .box {
      margin: 0px !important;
      width: 100%;
    }
  }
`;

const StyledLabel = styled.label<{ $css: boolean }>`
  padding: 5px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: ${({ $css }) => ($css ? COLORS.purple : COLORS.white)};
  transition: 0.3s ease, color 0.3s ease;
  input {
    display: none;
  }
  @media screen and (max-width: 429px) {
    margin: 5px !important;
  }
`;
