import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import axios from "axios";
import RecompBasic from "./Basic/RecompBasic";
import Premium from "./Premium/Premium";
import Gold from "./Gold/Gold";
import LilAdsGlobal from "../Ads/LilAdsGlobal";

const Points = () => {
  const [numChecked, setNumChecked] = useState("");
  const [checkPoints, setCheckPoints] = useState(false);
  const [point, setPoint] = useState<number>();
  const checkPoint = async () => {
    if (!numChecked) {
      return toast.error("Un numéro est requis");
    } else {
      try {
        const res = await axios({
          method: "get",
          url: `${process.env.REACT_APP_API}gain-point/check/${numChecked}`,
          withCredentials: true,
        });
        console.log(res);
        if (res.data.point) {
          setPoint(res.data.point);
        }
        if (res.data === "aucun") {
          setPoint(0);
        }
      } catch (error) {
        console.log(error);
        return toast.error(
          "Une erreur inattendue est survenue. Essayez plus tard"
        );
      }
    }
  };
  return (
    <StyledPoints $css={checkPoints}>
      <button
        onClick={() => setCheckPoints((prev) => !prev)}
        className="btn-display-check"
      >
        {checkPoints ? "Annuler" : "Vérifier"}
      </button>
      {checkPoints && (
        <div className="box-check-num">
          <strong>Réclamer votre récompense</strong>
          <div className="box-point">
            <strong>+ {numChecked}</strong>
            <span>
              Nombre de point : <strong>{point}</strong>
            </span>
          </div>
          <p>
            Une fois la récompense choisie, le nombre total de points est
            réinitialisé à 0.
          </p>
          <span>Ex: +594 694 xxx xxx</span>
          <PhoneInput
            country="gf"
            placeholder="Numéro whatsapp *"
            onChange={setNumChecked}
            value={numChecked ? numChecked : ""}
            containerClass="input-class"
          />
          <button onClick={() => checkPoint()}>Vérifier</button>
          <p>Un code sera envoyé par sms.</p>
        </div>
      )}
      <h2>Récompenses</h2>
      <RecompBasic />
      <LilAdsGlobal />
      <Premium />
      <Gold />
    </StyledPoints>
  );
};

export default Points;
const StyledPoints = styled.div<{ $css: boolean }>`
  /* background: pink; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px 0px;
  .btn-display-check {
    background: ${({ $css }) => ($css ? COLORS.danger : COLORS.purple)};
    padding: 10px;
    color: ${({ $css }) => ($css ? COLORS.white : COLORS.black)};
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    border: none;
    font-size: 1.3em;
    width: 20%;
    margin: 20px 0px;
    transition: 0.3s ease;
  }
  .btn-display-check:hover {
    transform: scale(1.1);
  }
  .box-check-num {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    background: ${COLORS.orange};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 20px;
    p {
      margin: 20px 0px;
      text-align: center;
      background: ${COLORS.danger};
      padding: 5px;
      color: ${COLORS.white};
    }
    .box-point {
      width: 50%;
      background: ${COLORS.orange};
      padding: 5px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      margin: 10px auto;
      span {
        display: block;
        text-align: center;
      }
    }
    strong {
      font-size: 2.1em;
      text-align: center;
      margin-bottom: 10px;
    }
    span {
      font-size: 0.8em;
      padding-left: 10px;
      margin-top: 10px;
    }
    .input-class {
      padding-left: 10px;
      margin-top: 10px;
    }
    button {
      width: 20%;
      padding: 10px;
      font-size: 1.3em;
      margin: 10px;
      border-radius: 5px;
      border: none;
      outline: none;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      cursor: pointer;
      background: ${COLORS.white};
      transition: 0.3s ease, color 0.3s ease;
    }
    button:hover {
      background: ${COLORS.purple};
    }
  }
  h2 {
    margin: 10px 0px;
    font-size: 2.5em;
    border-bottom: solid 2px ${COLORS.purple};
  }

  //width =< 429px
  @media screen and (max-width: 429px) {
    margin-top: 50px;
    .btn-display-check {
      width: 35%;
      border-radius: 10px;
    }
    .box-check-num {
      width: 100%;
      button {
        width: 35%;
      }
    }
    h2 {
      margin: 25px 0px;
      font-size: 2.2em;
      border-bottom: solid 2px ${COLORS.purple};
    }
  }
`;
