import React from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { COLORS } from "../../COLORS";
type TypeProps = {
  setAdsHome: React.Dispatch<React.SetStateAction<boolean>>;
};
const AdsHome = ({ setAdsHome }: TypeProps) => {
  return (
    <StyledAdsHome
      onClick={() => setAdsHome(true)}
      className="animate__animated animate__bounceIn"
    >
      <MdClose className="icon-close" onClick={() => setAdsHome(true)} />
      <img
        src="./items/home/inhome.png"
        alt="img ads Home"
        onClick={(e) => e.stopPropagation()}
      />
    </StyledAdsHome>
  );
};

export default AdsHome;

const StyledAdsHome = styled.div`
  position: fixed;
  background: greenyellow;
  height: 100vh;
  width: 100%;
  z-index: 80;
  background: #292928c2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon-close {
    color: ${COLORS.danger};
    font-size: 3em;
    cursor: pointer;
    margin-bottom: 10px;
  }
  img {
    width: 30%;
  }
  //width =< 429px
  @media screen and (max-width: 445px) {
    padding-top: 50px;
    justify-content: flex-start;
    img {
      width: 90%;
    }
  }
`;
