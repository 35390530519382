import React from "react";
import styled from "styled-components";

const FormBusiness = () => {
  return (
    <StyledFormBusiness>
      <div className="box-parent">
        en cours de developpement ( ici formulaire de street business)
      </div>
    </StyledFormBusiness>
  );
};

export default FormBusiness;

const StyledFormBusiness = styled.div`
  background: pink;
`;
