import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { vertDate } from "../../Utils/Fonctions";
import { COLORS } from "../../../COLORS";

type TypeProps = {
  createdAt: string;
  description: string;
  image: [string];
  ville: string;
  pseudo: string;
};
const Evenement = ({
  createdAt,
  description,
  image,
  ville,
  pseudo,
}: TypeProps) => {
  return (
    <StyledEvenement>
      <a href={image[0]} target="_blank">
        <img src={image[0]} alt="img events" />
        <span>{vertDate(createdAt)}</span>
      </a>
      <div className="details">
        {description && <span>Description : {description}</span>}
        <span>Publié par : {pseudo}</span>
        <span>Ville : {ville}</span>
      </div>
    </StyledEvenement>
  );
};

export default Evenement;
const StyledEvenement = styled.div`
  background: ${COLORS.blueLow};
  border-radius: 20px;
  margin: 5px;
  min-width: 45%;
  padding: 10px;
  display: flex;
  a {
    display: flex;
    flex-direction: column;
    width: 60%;
    text-decoration: none;
    margin-right: 10px;
    img {
      width: 100%;
      border-radius: 10px;
    }
    span {
      margin-top: 5px;
      color: ${COLORS.green};
      font-family: "Courier New", Courier, monospace;
      font-size: 0.8em;
    }
  }
  .details {
    width: 40%;
    display: flex;
    flex-direction: column;
    span {
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 442px) {
    min-width: 75%;
    padding: 3px;
    display: flex;
    flex-direction: column;
    a {
      width: 100%;
    }
    .details {
      width: 100%;
      span {
        font-size: 0.8em;
      }
    }
  }
`;
