import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../../COLORS";
import { toast } from "react-toastify";
import axios from "axios";
import BtnLoader from "../../../Utils/BtnLoader";
type TypeProps = {
  setMessageBox: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormFeedBack = ({ setMessageBox }: TypeProps) => {
  const [pseudo, setPseudo] = useState("");
  const [avis, setAvis] = useState("");
  const [hidden, setHidden] = useState("");
  const [load, setLoad] = useState(false);
  const handleSub = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!pseudo || !avis) {
      return toast.error("Les champs sont obligatoires 🤧");
    }
    if (pseudo.length > 20) {
      return toast.error("Pseudo trop long, max 20 caractères 🤧");
    }
    if (avis.length > 100) {
      return toast.error("Avis trop long, max 100 caractères 🤧");
    }
    setLoad(true);

    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}feed-back`,
        withCredentials: true,
        data: { pseudo, avis, hidden },
      });

      if (res.data.avis) {
        setLoad(false);
        setMessageBox(false);
        setAvis("");
        setPseudo("");
        return toast.success(res.data.avis);
      }
    } catch (error) {
      setLoad(false);
      console.log(error);
      return toast.error("Une erreur est survenue, essayez plus tard");
    }
  };
  return (
    <StyledFormFeedBack className="animate__animated animate__bounceInDown">
      <h2>Nous transmettre un avis sur l'envoi d'un évènement ? </h2>
      <form onSubmit={(e) => handleSub(e)}>
        <input
          type="text"
          placeholder="Pseudo"
          onChange={(e) => setPseudo(e.target.value)}
          value={pseudo ? pseudo : ""}
        />
        <input type="hidden" onChange={(e) => setHidden(e.target.value)} />
        <textarea
          placeholder="Avis..."
          value={avis ? avis : ""}
          onChange={(e) => setAvis(e.target.value)}
        ></textarea>
        {load ? <BtnLoader /> : <input type="submit" value="Envoyer" />}
      </form>
    </StyledFormFeedBack>
  );
};

export default FormFeedBack;
const StyledFormFeedBack = styled.div`
  background: ${COLORS.yellowLow};
  width: 70%;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  h2 {
    text-align: center;
  }
  form {
    width: 70%;
    /* background: blue; */
    display: flex;
    flex-direction: column;
    input,
    textarea {
      width: 30%;
      padding: 5px;
      outline: none;
      border: none;
      border-radius: 5px;
      margin: 5px 0px;
      font-size: 1.1em;
      border-bottom: solid 3px ${COLORS.green};
    }
    textarea {
      width: 50%;
      resize: none;
      height: 10vh;
    }
    input:last-child {
      background: ${COLORS.green};
      color: ${COLORS.white};
      cursor: pointer;
    }
  }
  //width =< 429px
  @media screen and (max-width: 441px) {
    width: 90%;
    form {
      width: 100%;
      input,
      textarea {
        width: 70%;
      }
    }
  }
`;
