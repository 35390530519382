export const vertDate = (value: string) => {
  const date = new Date(value);
  const now = new Date();

  // On définit aujourd'hui et hier en retirant le temps (heure, minute, seconde)
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Vérification de la date
  if (date >= today) {
    return "Publiée aujourd'hui";
  } else if (date >= yesterday && date < today) {
    return "Publiée hier";
  } else {
    // Si la date n'est ni aujourd'hui ni hier, on affiche le jour, mois et année
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" }); // Mois en toutes lettres
    const year = date.getFullYear();
    return `Publiée le ${day} ${month} ${year}`;
  }
};
