import React from "react";
import styled from "styled-components";

const LilAdsGlobal = () => {
  return (
    <StyledLilAdsGlobal>
      <div className="sign">
        <span className="fast-flicker">50 points </span> attribués pour
        <span className="flicker">votre</span> prochaine ann
        <span className="fast-flicker non">on</span>ce*
      </div>
      {/* <strong>50 points</strong> attribués pour votre prochaine annonce* */}
      <span>*Non accumulés</span>
    </StyledLilAdsGlobal>
  );
};

export default LilAdsGlobal;

const StyledLilAdsGlobal = styled.div`
  font-family: "Times New Roman", Times, serif;
  background-color: #141114;
  background-image: linear-gradient(335deg, black 23px, transparent 23px),
    linear-gradient(155deg, black 23px, transparent 23px),
    linear-gradient(335deg, black 23px, transparent 23px),
    linear-gradient(155deg, black 23px, transparent 23px);
  background-size: 58px 58px;
  background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
  position: relative;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  .sign {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    background-image: radial-gradient(
      ellipse 50% 35% at 50% 50%,
      #2c754c,
      transparent
    );
    transform: translate(-50%, -50%);
    letter-spacing: 2;
    left: 50%;
    top: 50%;
    font-family: "Clip";
    text-transform: uppercase;
    font-size: 1.1em;
    color: #ffe6ff;
    text-shadow: 0 0 0.3rem #ffe6ff, 0 0 1rem #56e795,
      -0.2rem 0.1rem 0.3rem #56e795, 0.2rem 0.1rem 0.3rem #56e795,
      0 -0.1rem 0.2rem #2fac66, 0 0.1rem 0.2rem #2fac66;
    /* 0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483; */
    animation: shine 2s forwards, flicker 3s infinite;
  }

  @keyframes blink {
    0%,
    22%,
    36%,
    75% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #56e795,
        -0.2rem 0.1rem 0.5rem #56e795, 0.2rem 0.1rem 0.5rem #56e795,
        1rem #2fac66, 0 0.5rem 2rem #2fac66;
    }
    28%,
    33% {
      color: #56e795;
      text-shadow: none;
    }
    82%,
    97% {
      color: #2fac66;
      text-shadow: none;
    }
  }

  .flicker {
    margin: 0px 10px;
    animation: shine 2s forwards, blink 3s 2s infinite;
  }

  .fast-flicker {
    margin: 0px 10px;
    animation: shine 2s forwards, blink 10s 1s infinite;
  }
  .non {
    margin: 0px !important;
  }

  @keyframes shine {
    0% {
      color: #6b1839;
      text-shadow: none;
    }
    100% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 0.5rem #56e795,
        -0.2rem 0.1rem 0.5rem #56e795, 0.2rem 0.1rem 0.5rem #56e795,
        0 -0.5rem 0.5rem #2fac66, 0 0.5rem 1rem #2fac66;
    }
  }

  @keyframes flicker {
    from {
      opacity: 1;
    }

    4% {
      opacity: 0.9;
    }

    6% {
      opacity: 0.85;
    }

    8% {
      opacity: 0.95;
    }

    10% {
      opacity: 0.9;
    }

    11% {
      opacity: 0.922;
    }

    12% {
      opacity: 0.9;
    }

    14% {
      opacity: 0.95;
    }

    16% {
      opacity: 0.98;
    }

    17% {
      opacity: 0.9;
    }

    19% {
      opacity: 0.93;
    }

    20% {
      opacity: 0.99;
    }

    24% {
      opacity: 1;
    }

    26% {
      opacity: 0.94;
    }

    28% {
      opacity: 0.98;
    }

    37% {
      opacity: 0.93;
    }

    38% {
      opacity: 0.5;
    }

    39% {
      opacity: 0.96;
    }

    42% {
      opacity: 1;
    }

    44% {
      opacity: 0.97;
    }

    46% {
      opacity: 0.94;
    }

    56% {
      opacity: 0.9;
    }

    58% {
      opacity: 0.9;
    }

    60% {
      opacity: 0.99;
    }

    68% {
      opacity: 1;
    }

    70% {
      opacity: 0.9;
    }

    72% {
      opacity: 0.95;
    }

    93% {
      opacity: 0.93;
    }

    95% {
      opacity: 0.95;
    }

    97% {
      opacity: 0.93;
    }

    to {
      opacity: 1;
    }
  }

  @media screen and (max-width: 429px) {
    .sign {
      font-size: 0.8em;
    }
  }
`;
