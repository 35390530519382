import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../COLORS";
import axios from "axios";
import { toast } from "react-toastify";
import BtnLoader from "../Utils/BtnLoader";
import { useNavigate } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";

const Footer = ({
  setCompo,
}: {
  setCompo: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const nav = useNavigate();
  const linkInsta = () => {
    const url = "https://www.instagram.com/emohup";
    window.open(url, "_blank");
  };
  return (
    <StyledFooter>
      <div className="first">
        <img src="./items/logoRound.svg" alt="logo-footer" />
      </div>
      <div className="second">
        <strong>Informations légales</strong>
        <ol className="list">
          <li onClick={() => setCompo("recompense")}>Infos Récompense</li>
          <li onClick={() => setCompo("cgu")}>CGU</li>
          <li onClick={() => setCompo("mentionlegales")}>Mentions légales</li>
          <li onClick={() => setCompo("politique")}>
            Politique de confidentialité
          </li>
        </ol>
      </div>
      <div className="third">
        <strong>Contacts</strong>
        {/* <a href="https://www.instagram.com/emohup/" target="_blank"></a> */}
        <button onClick={() => nav("/contact")}>Nous contacter ?</button>
        <BsInstagram className="insta" onClick={() => linkInsta()} />
      </div>
      <span className="copy-right">
        ©copyright - 2024 - dev.frenchlod@gmail.com
      </span>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  margin-top: 10px;
  border-top: solid 2px ${COLORS.grey};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  .copy-right {
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }
  .first {
    width: 25%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
    }
  }
  .second {
    padding: 10px;
    strong {
      font-size: 1.5em;
    }
    .list {
      display: flex;
      flex-direction: column;
      li {
        display: block;
        cursor: pointer;
        margin-top: 10px;
        color: ${COLORS.black};
        text-decoration: underline;
      }
    }
  }
  .third {
    display: flex;
    flex-direction: column;
    padding: 10px;
    .insta {
      color: ${COLORS.green};
      font-size: 2em;
      cursor: pointer;
    }
    strong {
      font-size: 1.5em;
    }
    input {
      margin-top: 15px;
      padding: 5px;
      border-radius: 5px;
      border: none;
      outline: none;
      font-size: 1.3em;
    }
    button {
      font-size: 1.8em;
      background: ${COLORS.green};
      padding: 10px;
      color: ${COLORS.white};
      cursor: pointer;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 5px !important;
      border: none;
      outline: none;
      border-radius: 5px;
    }
  }
  //width =< 429px
  @media screen and (max-width: 429px) {
    padding-bottom: 100px;
    .first {
      width: 50%;
      padding: 0px;
      img {
        width: 100%;
      }
    }
    .second {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      strong {
        text-align: center;
      }
      ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .third {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: solid 2px ${COLORS.green};
      strong {
        display: block;
        text-align: center;
      }
    }
  }
`;
